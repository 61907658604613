import styled, { css } from 'styled-components';

type MenuBoxProps = {
  vertical?: boolean;
};

export const MenuBox = styled.div<MenuBoxProps>`
  position: relative;
  font-size: 16px;
  display: flex;
  gap: 2em;
  ${({ vertical }) =>
    vertical &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 3em;
      font-size: 18px;
    `};

  div.select {
    position: relative;
    width: auto;

    select {
      appearance: none;
      background-color: transparent;
      border-radius: 0;
      border: none;
      padding: 0 1em 0.2em 0;
      margin: 0;
      width: auto;
      min-width: min-content;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      text-transform: capitalize;
      outline: none;
      border-bottom: 1.5px dashed ${({ theme }) => theme.colors.dark};
    }

    select::-ms-expand {
      display: none;
    }

    &:after {
      pointer-events: none;
      content: '';
      width: 0.8em;
      height: 0.5em;
      background-color: #474747;
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      position: absolute;
      right: 0;
      top: 0.3em;
    }
  }
`;

export const MenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column;
  gap: 0.3em;
  cursor: pointer;

  text-decoration: none;
  color: inherit;

  span {
    display: block;
    text-align: center;
    padding-bottom: 4px;
    border-bottom: 1.5px dashed ${({ theme }) => theme.colors.dark};
  }

  svg,
  img {
    height: 1.5em;
    width: auto;
    transition: transform 200ms;
    transform: translateZ(0);
    transform-origin: 50% 100%;
    display: inline-block;
  }

  &:hover {
    svg {
      transform: scale(1.1);
    }
    span {
      border-style: solid;
    }
  }
`;
