import * as s from './Styles'
import { ModalContext } from 'App'
import { useContext } from 'react'
import { ReactComponent as BackIcon } from 'assets/img/back.svg'

type ButtonProps = {
	style?: React.CSSProperties
	className?: string
}


const Modal = ({ style, className }: ButtonProps) => {
	const modalControl = useContext(ModalContext);
	return (
		modalControl?.state ?
			<s.Container className={className} style={style}>
				<s.Back><BackIcon onClick={() => modalControl?.update(null)} /></s.Back>
				{modalControl.state?.body}
			</s.Container >
			:
			null
	)
}

export default Modal