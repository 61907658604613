import React, { useEffect, useState, startTransition  } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import MobileMenu from 'components/MobileMenu/MobileMenu';
import Modal from 'components/Modal/Modal';
import ScrollTop from 'components/ScrollTop/ScrollToTop';
import Header from 'components/Header/Header';
import { footerHeaderType, useFetchData } from 'api/api';
import useLocalStorageState from 'use-local-storage-state';
import { locale } from 'utils/useLang';
import useLang from 'utils/useLang';
import { Suspense } from 'react';
import { Helmet } from "react-helmet"
import { YMInitializer } from 'react-yandex-metrika';
const MainPage = React.lazy(() => import('pages/MainPage/MainPage'));
const Useful = React.lazy(() => import('pages/Useful/Useful'));
const AppDownload = React.lazy(() => import('pages/AppDownload/AppDownload'));
const Footer = React.lazy(() => import('components/Footer/Footer'));
const Library = React.lazy(() => import('pages/Library/Library'));
const Help = React.lazy(() => import('pages/Help/Help'));
const Delivery = React.lazy(() => import('pages/Delivery/Delivery'));
const Warranty = React.lazy(() => import('pages/Warranty/Warranty'));
const NotFound = React.lazy(() => import('pages/NotFound/NotFound'));


type ModalObj = {
  title: string;
  body: React.ReactNode;
};

type ContextObj = {
  state: ModalObj | null;
  update: React.Dispatch<React.SetStateAction<ModalObj | null>>;
};

export const ModalContext = React.createContext<ContextObj | null>(null);

function App() {
  let location = useLocation();
  useLang();
  const [lang] = useLocalStorageState<string>('lang');
  const [locales] = useLocalStorageState<locale[]>('locales');
  
  let localesLink: any[] = [];

  if (locales != undefined){
  locales.forEach((locale, index) => {
    if (locale.code != lang){
      let linkLocale = '/changelang/' + locale.code;
        localesLink.push({
          "rel": "alternate", 
          "hreflang": locale.code, 
          "href": location.pathname + '?lang=' + locale.code
        });
    };
  });};

  const [modalContent, setModalContent] = useState<ModalObj | null>(null);

  useEffect(() => {
    startTransition(() => {
      setModalContent(null)});
  }, [location.pathname]);

  const headerFooterData = useFetchData({
    path: 'header-footer-content',
  }) as footerHeaderType;

  useFetchData({
    path: 'main-page-content',
  });

  return (
    <>
      <ModalContext.Provider
        value={{
          state: modalContent,
          update: setModalContent,
        }}>
        <Helmet htmlAttributes={{ "lang": lang }}
                link={localesLink}> </Helmet>
        <ScrollTop />
        <Suspense fallback="">
        <MobileMenu data={headerFooterData?.menu} />
        <Header
          data={headerFooterData}
          $small={location.pathname === '/' ? false : true}
        />
        </Suspense>
         <YMInitializer accounts={[75289408]}/>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route
              path='/'
              element={<MainPage />}
            />
            <Route
              path='/delivery'
              element={<Delivery />}
            />
            <Route
              path='/shop'
              element={<Delivery />}
            />
            <Route
              path='/warranty'
              element={<Warranty />}
            />
            <Route
              path='/library'
              element={<Library />}
            />
            <Route
              path='/help'
              element={<Help />}
            />
            <Route
              path='/useful'
              element={<Useful />}
            />

            <Route
              path='/appdownload'
              element={<AppDownload />}
            />

            <Route
              path='/appdownloadnonanny'
              element={<AppDownload />}
            />
            <Route
              path='*'
              element={<NotFound />}
            />
          </Routes>
        </Suspense>
        <Suspense fallback="">
        {headerFooterData ? (
          <Footer data={headerFooterData.footerContent} />
        ) : null}
        </Suspense>
        <Modal />
      </ModalContext.Provider>
    </>
  );
}

function Loading() {
  return (<div className="wrap"><h2>🌀 Loading...</h2></div>);
}


export default App;
