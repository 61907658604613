import * as s from './Styles';
import { Link } from 'react-router-dom';
import { ModalContext } from 'App';
import { useContext } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { menuItemType } from 'api/api';
import { locale } from 'utils/useLang';
import { Helmet } from 'react-helmet';

const TopMenu = ({
  vertical,
  data,
  ...props
}: {
  vertical?: boolean;
  data: menuItemType[] | undefined;
}) => {
  const modalControl = useContext(ModalContext);

  function modalOff() {
    modalControl?.update(null);
  }

  const [lang, setLang] = useLocalStorageState<string>('lang');
  const [locales] = useLocalStorageState<locale[]>('locales');

  return (
    <s.MenuBox
      vertical={vertical}
      {...props}>
      {data?.map((x, i) => {
        switch (x.link) {
          case '/contacts':
            return (
              <s.MenuItem
                key={i}
                onClick={() => {
                  modalOff();
                  window.scrollTo(0, document.body.scrollHeight);
                }}>
                <img
                  src={process.env.REACT_APP_APIHOST + x.icon?.url}
                  alt={x.label}
                />
                <span>{x.label}</span>
              </s.MenuItem>
            );
          default:
            return (
              <s.MenuItem
                key={i}
                onClick={() => modalOff()}
                as={Link}
                to={x.link}>
                <img
                  src={process.env.REACT_APP_APIHOST + x.icon?.url}
                  alt={x.label}
                />
                <span>{x.label}</span>
              </s.MenuItem>
            );
        }
      })}
      <s.MenuItem>
        <img
          src={process.env.PUBLIC_URL + `/${lang}.png`}
          alt={lang || ''}
        />
        <div className='select'>
          <select
            value={lang}
            onChange={(event) => {
              setLang(event.target.value);
              console.log(event.target.value);
              modalOff();
            }}>
            {locales &&
              locales?.map((x) => {
                return (
                  <option
                    key={x.id}
                    value={x.code}>
                    {x.code}
                  </option>
                );
              })}
          </select>
        </div>
      </s.MenuItem>
    </s.MenuBox>
  );
};

export default TopMenu;
