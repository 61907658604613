import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
    border-radius: 0 300% 300% 0;
    background: rgba(26, 255, 91, 0.8);
  }

  to {
    transform: scale(1);
    opacity: 1;
    background: rgba(255, 245, 210, 0.80);
  }
`;



export const Container = styled.div`
  animation: ${rotate} .3s ease;
position: fixed;
transform-origin: 0 0;
z-index: 99;
left: 0;
top: 0;
right: 0;
bottom: 0;
background: rgba(255, 245, 210, 0.80);
backdrop-filter: blur(5px);
display: flex;
align-items: center;
justify-content: center;
`


export const Back = styled.div`
position: absolute;
left: 10%;
top: 5%;

svg {
  width: auto;
  height: 10vh;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    transform: rotate(-10deg) scale(1.1);
  }
}
`



