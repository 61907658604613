import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import balooFont from '../fonts/Baloo.ttf';
import AyuthayaFont from '../fonts/Ayuthaya.ttf';
import slabFont from '../fonts/Slab.ttf';
import line from 'assets/img/line.svg';

export const GlobalStyle = createGlobalStyle`
${reset}

@font-face {
  font-family: 'slab';
  src: url(${slabFont}) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'slab';
  src: url(${slabFont}) format('truetype');
  font-display: swap;
  font-weight: bold;
}

@font-face {
  font-family: 'baloo';
  src: url(${balooFont}) format('truetype');
  font-display: swap;
  font-weight: bold;
}


@font-face {
  font-family: 'ayuthaya';
  src: url(${AyuthayaFont}) format('truetype');
  font-display: swap;
}


* {
  box-sizing: border-box;
}

html, body {
  position: relative;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility; 
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

}

body {
  font-family: 'slab';
  background-color: #fff;
  color:  ${({ theme }: any) => theme.colors.dark};
  font-size: 18px;
}

html, body, #root {
  position: relative;
  width: 100%;
  min-height: 100vh;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
}

.wrap {
  position: relative;
  z-index: 2;
  width: 90vw;
  max-width: 1050px;
  margin: 0 auto;
  @media (max-height: 600px),(max-width: 850px) {
  text-align: center;
  overflow: hidden;
}

p {
  line-height: 150%;
  font-family:  ${({ theme }: any) => theme.fonts.default};
  padding-bottom: 1.2em;
  font-size: 18px;
  
  
  &::selection {
    background: #fff;
    color: ${({ theme }: any) => theme.colors.black};
  }
}

hr {
  position: relative;
  padding-top: 5%;
  margin: 0;
  margin-bottom: 1rem;
  background-image: url(${line});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: 0;
  outline: 0;
}

h2 {
  font-size: max(3.7vw, 35px);
  margin-bottom: 4vh;
  line-height: 110%;
  font-weight: 500;
  max-width: 800px;


  small {
    display: block;
    font-size: max(18px,0.4em);
    font-family: 'ayuthaya';
    line-height: 110%;
    margin-top: .5em;
  }
}

h1 {
  font-size: max(3.7vw, 35px);
  margin-bottom: 4vh;
  line-height: 110%;
  font-weight: 500;
  max-width: 800px;


  small {
    display: block;
    font-size: max(18px,0.4em);
    font-family: 'ayuthaya';
    line-height: 110%;
    margin-top: .5em;
  }
}

a {text-decoration: none;

}

h3 {
  font-weight: bold;
  font-size: max(1.5vw, 22px);
  margin-bottom: .7em;
  line-height: 150%;
}
h4 {
  color: #fff;
  font-weight: bold;
  margin-bottom: 1em;
}

ul {
  list-style-type: disc;
  li {
    margin-bottom: 0.5em;
  }
}

img {
  position: relative;
  max-width: 100%;
}

a {
  font-weight: bold;
  color: ${({ theme }: any) => theme.colors.sea};

  &:hover {
    text-decoration: none;
  }
}
}

`;

export const theme = {
  colors: {
    dark: '#3D454F',
    sea: '#58ADB0',
    yellow: '#FFDF92',
    green: '#F1F6DE',
    pink: '#DE88BD',
    orange: '#E27D65',
    violet: '#E5EDEF',
  },
  fonts: {
    default: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
  },
  padding: '3.5vw',
  rightSliderWidth: '70%',
};
