import * as s from './Styles'

type ButtonProps = {
  icon?: React.ReactNode
  style?: React.CSSProperties
  className?: string
  text?: string
  onClick?: () => void
}

const Button = ({ icon, style, className, text, onClick }: ButtonProps) => {
  return (
    <s.Button onClick={onClick} className={className} style={style}>
      <span>{text}</span>
      {icon}
    </s.Button>
  )
}

export default Button
