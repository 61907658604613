import styled from 'styled-components'



export const MobMenuBox = styled.div`
position: fixed;
z-index: 100;
left: 0;
width: 100vw;
display: flex;
align-items: center;
justify-content: center;

.burger {
  position: absolute;
  left: 5vw;
  top: 2.1vh;
  width: 43px;
  height: 43px;
  background-color: ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 200ms;

  svg {
    width: 60%;
    height: auto;
    color: #fff;
  }

  &:hover {
    transform: scale(1.1);
  }
}

&>svg {
  width: auto;
  height: 34px;
  margin: 3vh 0;
}

display: none;
@media (max-height: 600px),(max-width: 850px) {
  display: flex;
}

`

