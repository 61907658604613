import styled from 'styled-components';

export const Button = styled.button`
  position: relative;
  border: 0;
  font-size: 20px;
  font-family: inherit;
  font-weight: bold;
  cursor: pointer;
  backdrop-filter: blur(10px);
  background: rgba(220, 240, 141, 0.8);
  /* background: rgba(255, 255, 255, 0.8); */
  border-radius: 50px;
  padding: 0.5em 1.5em;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  gap: 0.3em;
  transition: transform 200ms;

  span {
    position: relative;
    font-weight: bold;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.dark};
      border-radius: 2px;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: transform 400ms;
    }
  }

  svg,
  img {
    position: relative;
    height: 2.7em;
    width: auto;
    transition: transform 200ms;
    transform-origin: 45% 55%;
    transition: 200ms;
  }

  &:hover {
    svg,
    img {
      transform: scale(1.2) rotate(10deg);
    }

    span {
      &::after {
        transform: scaleX(1);
      }
    }
  }
`;
